import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import {Order, OrderItem} from 'src/app/models/pos_order';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {NativeInterfacesService} from 'src/app/services/native-interfaces.service';
import {PosOrdersService} from 'src/app/services/pos-orders.service';
import {TitleService} from 'src/app/services/title.service';
import {PosOrderItemListComponent} from '../../components/pos-order-item-list/pos-order-item-list.component';
import {
  SimpleTextDialogComponent
} from '../../components/dialogs/simple-text-dialog-component/simple-text-dialog.component';
import {PosProductService} from '../../services/pos-products.service';
import {
  AbstractParentDetailComponent,
  ParentItemAction
} from '../../components/abstract-parent-detail/abstract-parent-detail.component';
import {PosOrdersItemService} from '../../services/pos-orders-item.service';
import {UsersService} from '../../services/users.service';
import {environment} from 'src/environments/environment';
import {takeUntil} from 'rxjs/operators';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel
} from '../../components/utils/confirm-dialog/confirm-dialog.component';
import {Fab, FabAction, FabTypes} from '../../components/fab-custom/fab-interface';
import {ClientsListComponent} from '../clients-list/clients-list.component';
import {Observable} from 'rxjs';
import {User} from 'src/app/models/user';
import {PaymentStatusCardComponent} from '../../components/payment-status-card/payment-status-card.component';
import {ClientsDetailComponent} from '../clients-detail/clients-detail.component';
import {BudgetStates} from '../../models/abstract_base_api_item';
import {AppMetricsService} from '../../services/app-metrics.service';


@Component({
  selector: 'app-pos-orders-detail',
  templateUrl: '../../components/abstract-parent-detail/abstract-parent-detail.component.html',
  styleUrls: ['../../components/abstract-parent-detail/abstract-parent-detail.component.scss']
})
export class PosOrdersDetailComponent extends AbstractParentDetailComponent<Order> implements OnInit, OnDestroy {

  constructor(
    private hotkeysService: HotkeysService,
    protected titleService: TitleService,
    protected translate: TranslateService,
    protected notificationService: CrmNotificationsService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected router: Router,
    protected dialog: MatDialog,
    protected progressDialog: MatDialog,
    protected route: ActivatedRoute,
    private posOrdersService: PosOrdersService,
    private posProductService: PosProductService,
    private posOrdersItemService: PosOrdersItemService,
    protected usersService: UsersService,
    private resolver: ComponentFactoryResolver,
    public native: NativeInterfacesService,
    public appMetricsService: AppMetricsService
  ) {
    super(
      titleService,
      translate,
      notificationService,
      nativeInterfacesService,
      router,
      dialog,
      progressDialog,
      route,
      posOrdersService,
      usersService,
      appMetricsService
    );
  }

  navBase = '/pos/orders/';

  titleI18N = 'SALE';
  titleIcon = 'shopping_cart';
  deleteI18N = 'DELETE_SALE';

  @ViewChild(PosOrderItemListComponent) childItemListRef: PosOrderItemListComponent;
  @ViewChild('childListHost', {read: ViewContainerRef, static: false}) childListHost;
  @ViewChild('paymentCard') paymentCard: PaymentStatusCardComponent;

  primaryFab = new Fab(
    'ADD_PRODUCT',
    'add',
    'fab_add_product',
    FabTypes.multipleAction,
    [
      new FabAction('SEARCH_PRODUCT', '', 'search_product'),
      new FabAction('ADD_BY_CODE', '', 'add_by_code'),
      new FabAction('ADD_PRODUCT_MANUALLY', '', 'add_item_manually')
    ]);
  secondaryFab = null;

  actions = [];

  documentTypes = {
    // tslint:disable-next-line:max-line-length
    invoice: {enabled: true, nameI18N: 'INVOICE', disable_reason: '', whatsapp_enabled: true, mail_enabled: true, pdf_enabled: true, print_enabled: true},
    // tslint:disable-next-line:max-line-length
    ticket: {enabled: true, nameI18N: 'TICKET', disable_reason: '', whatsapp_enabled: true, mail_enabled: true, pdf_enabled: true, print_enabled: true},
    // tslint:disable-next-line:max-line-length
    budget: {enabled: true, nameI18N: 'BUDGET', disable_reason: '', whatsapp_enabled: true, mail_enabled: true, pdf_enabled: true, print_enabled: true}
  };

  loadChildListComponent(): void {
    // TODO: migrate use componentfactory to viewcontaineref.createcomponent
    //    https://stackoverflow.com/a/70947152/888245
    // Dynamically create the component, so we don't need multiple templates for different types of lists
    this.childListHost.clear();
    const factory: ComponentFactory<PosOrderItemListComponent> = this.resolver.resolveComponentFactory(PosOrderItemListComponent);
    this.childListRefComponentRef = this.childListHost.createComponent(factory);
    this.childListRefComponentRef.instance.parentApiContentTypeName = 'order';
    this.childListRefComponentRef.instance.parent = this.parentItem;
    this.childItemListRef = this.childListRefComponentRef.instance;

    if (this.parentItem.invoiceNumber && this.parentItem.invoiceNumber.length > 3) {
      this.childItemListRef.showVatFields(true);
      // TODO: instead of redefine all the array to remove just one item, remove the item
      // TODO: replicate logic used on bss, it's all on the template
      this.actions = [
        new ParentItemAction('PRINT_TICKET', '', 'print_ticket'),
        new ParentItemAction('ASSIGN_TICKET_TO_CLIENT', '', 'assign_ticket_to_client'),
        new ParentItemAction('PRINT_INVOICE', '', 'print_invoice'),
        new ParentItemAction('SHOW_HIDE_VAT', '', 'commute_vat'),
        new ParentItemAction('SEND_INVOICE_BY_EMAIL', '', 'send_invoice_by_email'),
        new ParentItemAction('SEND_TICKET_BY_EMAIL', '', 'send_ticket_by_email'),
        new ParentItemAction('DELETE_SALE', '', 'delete_order'),
      ];
    }
    // subscribe to child output event, remember to unsubscribe on destroy
    this.childListRefComponentRef.instance.itemsChange.subscribe(val => {
      this.parentItem.totalAmountWithTax = this.childListRefComponentRef.instance.getTotalCost();
      this.paymentCard.updatePaymentStatus();
    });
  }

  ngOnInit(): void {
    const clientID = this.route.snapshot.paramMap.get('clientId') || this.route.snapshot.paramMap.get('parentId');
    if (clientID) {
      this.initialCreateFormData.append('client', clientID);
    }

    // TODO: is there a better way?
    const isNewBudget = this.router.url.indexOf('budgets/create') !== -1;
    if (isNewBudget) {
      this.initialCreateFormData.append('budgetStatus', BudgetStates.NeedsApproval);
    }

    super.ngOnInit();
    // TODO: move to an abstract class as 'title' or 'page title' or something on those lines:
    this.titleService.setTitleTranslated(null);

    if (this.nativeInterfacesService.hasCamera) {
      this.secondaryFab = new Fab('', 'qr_code_scanner', 'fab_add_by_cam_code_scanner', FabTypes.singleAction);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  updateDocumentTypes(): void {
    super.updateDocumentTypes();
    if (!this.parentItem.clientMail || this.parentItem.clientMail.length < 3) {
      for (const docTypeKey of Object.keys(this.documentTypes)) {
        this.documentTypes[docTypeKey].mail_enabled = false;
        this.documentTypes[docTypeKey].disable_reason = 'USER_DOES_NOT_HAVE_ANY_MAIL';
      }
    }
    if (!this.parentItem.clientPhoneFormatted || this.parentItem.clientPhoneFormatted.length < 3) {
      for (const docTypeKey of Object.keys(this.documentTypes)) {
        this.documentTypes[docTypeKey].whatsapp_enabled = false;
        this.documentTypes[docTypeKey].disable_reason = 'USER_DOES_NOT_HAVE_ANY_PHONE';
      }
    }
    if (this.parentItem.budgetStatus === BudgetStates.DontNeedApproval || this.parentItem.budgetStatus === BudgetStates.Approved) {
      this.documentTypes.budget.enabled = false;
      this.documentTypes.budget.disable_reason = 'SALE_IS_ALREADY_APPROVED';
    }
  }

  onParentItemChange(): void {
    super.onParentItemChange();
    if (this.parentItem.budgetStatus === BudgetStates.DontNeedApproval || this.parentItem.budgetStatus === BudgetStates.Approved) {
      this.titleI18N = 'SALE';
      this.titleIcon = 'shopping_cart';
      this.actions = [
        new ParentItemAction('PRINT_TICKET', '', 'print_ticket'),
        new ParentItemAction('ASSIGN_TICKET_TO_CLIENT', '', 'assign_ticket_to_client'),
        new ParentItemAction('PRINT_INVOICE', '', 'print_invoice'),
        new ParentItemAction('SHOW_HIDE_VAT', '', 'commute_vat'),
        new ParentItemAction('SEND_INVOICE_BY_EMAIL', '', 'send_invoice_by_email'),
        new ParentItemAction('SEND_TICKET_BY_EMAIL', '', 'send_ticket_by_email'),
        new ParentItemAction('DELETE_SALE', '', 'delete_order'),
      ];
    } else if (this.parentItem.budgetStatus === BudgetStates.NeedsApproval) {
      this.titleI18N = 'BUDGET';
      this.titleIcon = 'receipt';
      this.actions = [
        // new ParentItemAction('PRINT_BUDGET', '', 'print_ticket'),
        new ParentItemAction('MARK_BUDGET_AS_APPROVED', '', 'mark_budget_as_approved'),
        new ParentItemAction('MARK_BUDGET_AS_DENIED', '', 'mark_budget_as_denied'),
        new ParentItemAction('ASSIGN_BUDGET_TO_CLIENT', '', 'assign_ticket_to_client'),
        new ParentItemAction('SHOW_HIDE_VAT', '', 'commute_vat'),
        new ParentItemAction('DELETE_BUDGET', '', 'delete_order'),
      ];
    } else if (this.parentItem.budgetStatus === BudgetStates.Denied) {
      this.titleI18N = 'BUDGET';
      this.titleIcon = 'receipt';
      this.actions = [
        new ParentItemAction('MARK_BUDGET_AS_APPROVED', '', 'mark_budget_as_approved'),
        new ParentItemAction('ASSIGN_BUDGET_TO_CLIENT', '', 'assign_ticket_to_client'),
        new ParentItemAction('DELETE_BUDGET', '', 'delete_order'),
      ];
    } else {
      throw new Error('Unexpected budget status');
    }
  }

  configureKeyboardShortcuts(): void {
    if (!this.isFully) {
      // Hot Keys:
      // https://github.com/brtnshrdr/angular2-hotkeys
      this.hotkeysService.add(
        new Hotkey(
          'c', // key combination
          (): boolean => { // callback function to execute after key combination
            this.addOrderItem();
            return false; // prevent bubbling
          },
          undefined, // allow shortcut execution in these html elements
          'Add product' // shortcut name // TODO: translate i18n
        )
      );
      this.hotkeysService.add(
        new Hotkey(
          'C', // key combination
          (): boolean => { // callback function to execute after key combination
            this.childItemListRef.addByCodeDialog();
            return false; // prevent bubbling
          },
          undefined, // allow shortcut execution in these html elements
          'Add product by code' // shortcut name // TODO: translate i18n
        )
      );
      this.hotkeysService.add(
        new Hotkey(
          's', // key combination
          (): boolean => { // callback function to execute after key combination
            this.childItemListRef.searchExistingProductDialog();
            return false; // prevent bubbling
          },
          undefined, // allow shortcut execution in these html elements
          'Search product' // shortcut name // TODO: translate i18n
        )
      );
    }
  }

  addOrderItem(orderItem?: OrderItem): void {
    this.childItemListRef.addItemEnd(orderItem);
  }

  onFabAction(actionId: string): boolean {
    if (actionId === 'search_product') {
      this.childItemListRef.searchExistingProductDialog();
      return true;
    }
    if (actionId === 'add_by_code') {
      this.childItemListRef.addByCodeDialog();
      return true;
    }
    if (actionId === 'add_item_manually') {
      this.addOrderItem();
      return true;
    }
    if (actionId === 'fab_add_by_cam_code_scanner') {
      this.childItemListRef.startCamBarcodeScanner();
      return true;
    }
    console.log(`TODO: implement ${actionId}`);
    return true;
  }

  downloadBudgetPDF(): void {
    window.open(`${environment.apiUrl}/pos/order/reports/${this.parentId}/budget?tk=${this.usersService.userTokenValue.token}&pdf=1`, '_blank');
  }

  onAction(actionId: string): boolean {
    if (actionId === 'print_ticket') {
      this.printTicket();
      return true;
    }
    if (actionId === 'mark_budget_as_approved') {
      this.posOrdersService.modify(this.parentId, {budgetStatus: BudgetStates.Approved}).subscribe((x) => {
        this.parentItem = x;
        this.onParentItemChange();
      });
      return true;
    }
    if (actionId === 'mark_budget_as_denied') {
      this.posOrdersService.modify(this.parentId, {budgetStatus: BudgetStates.Denied}).subscribe((x) => {
        this.parentItem = x;
        this.onParentItemChange();
      });
      return true;
    }
    if (actionId === 'assign_ticket_to_client') {
      this.assignTicketToClient();
      return true;
    }
    if (actionId === 'print_invoice') {
      this.printInvoice();
      return true;
    }
    if (actionId === 'delete_order') {
      this.deleteOrder();
      return true;
    }
    if (actionId === 'commute_vat') {
      this.childItemListRef.showVatFields(this.childItemListRef.fields.vat.metadata.hidden);
      return true;
    }

    if (actionId === 'send_ticket_by_email') {
      this.sendTicketByMail();
      return true;
    }
    if (actionId === 'send_invoice_by_email') {
      this.sendInvoiceByMail();
      return true;
    }
    if (actionId === 'download_budget_pdf') {
      this.downloadBudgetPDF();
      return true;
    }
    console.log(`TODO: implement ${actionId}`);
    return true;
  }

  async checkClientAssignedHasEmail(client: User): Promise<string> {
    if (client.email || client.email.length > 3) {
      return client.email;
    }
    const newEmail = await this.getNewEmail().toPromise();
    if (!newEmail || newEmail.length < 3) {
      return;
    }
    client.email = newEmail;
    await this.usersService.modify(client.id, {email: newEmail}).toPromise();
    return client.email;

  }

  async checkClientAssigned(): Promise<User> {
    if (this.parentItem.client == null) {
      const selectedUser = await this.openClientSelector().toPromise();
      if (!selectedUser) {
        return;
      }
      this.parentItem.client = selectedUser.id;
      this.saveParentItem();
    }
    return await this.usersService.get(this.parentItem.client).toPromise();
  }

  async sendInvoiceByMail(): Promise<void> {
    // TODO: replace all async/await promises for flatmap or something like that (has ii any advantages?
    //        being able to cancel things for example?
    //        .toPromise is deprecated, so that's that... replace with something like firstValueFrom or lastValueFrom

    // TODO: give option to create new user

    const client = await this.checkClientAssigned();
    if (client == null) {
      await this.notificationService.errorI18N('SALE_MUST_BE_ASSIGNED_TO_CLIENT_BEFORE_SENDING_IT');
      return;
    }

    const email = await this.checkClientAssignedHasEmail(client);
    if (!email || email.length < 3) {
      await this.notificationService.errorI18N('USER_DOES_NOT_HAVE_ANY_EMAIL_PLEASE_SET_ONE');
      return;
    }

    const r = await this.usersService.confirmInvoiceInformation(client, ClientsDetailComponent).toPromise();
    if (r == null) {
      await this.notificationService.infoI18N('INVOICE_SENDING_CANCELLED_BY_USER');
      return;
    }


    this.posOrdersService.sendInvoiceToClient(this.parentId).subscribe(async () => {
      await this.notificationService.successI18N('INVOICE_SENT_TO_CLIENT');
    }, async (err) => {
      await this.notificationService.errorI18N('ERROR_SENDING_INVOICE_TO_CLIENT');
    });
  }

  async sendTicketByMail(): Promise<void> {
    // TODO: replace all async/await promises for flatmap or something like that (has ii any advantages?
    //        being able to cancel things for example?
    //        .toPromise is deprecated, so that's that... replace with something like firstValueFrom or lastValueFrom

    const client = await this.checkClientAssigned();
    if (client == null) {
      await this.notificationService.errorI18N('SALE_MUST_BE_ASSIGNED_TO_CLIENT_BEFORE_SENDING_IT');
      return;
    }

    const email = await this.checkClientAssignedHasEmail(client);
    if (!email || email.length < 3) {
      await this.notificationService.errorI18N('USER_DOES_NOT_HAVE_ANY_EMAIL_PLEASE_SET_ONE');
      return;
    }


    this.posOrdersService.sendTicketToClient(this.parentId).subscribe(async () => {
      await this.notificationService.successI18N('TICKET_SENT_TO_CLIENT');
    }, async (err) => {
      await this.notificationService.errorI18N('ERROR_SENDING_TICKET_TO_CLIENT');
    });
  }

  openClientSelector(): Observable<User> {
    const dialogRef = this.dialog.open(ClientsListComponent, {
      height: '90%',
      width: '90%',
      maxWidth: '90%',
      panelClass: 'no-padding-dialog-container',
      data: {
        mode: 'client-selector'
      }
    });

    return dialogRef.afterClosed();
  }

  getNewEmail(): Observable<string> {
    const dialogRef = this.dialog.open(SimpleTextDialogComponent, {
      maxWidth: '90%',
      panelClass: 'no-padding-dialog-container',
      data: {
        mode: 'floating-selector'
      }
    });
    dialogRef.componentInstance.showBarCodeButton = true;
    dialogRef.componentInstance.titleI18N = 'USER_DOES_NOT_HAVE_ANY_EMAIL_PLEASE_SET_ONE';
    dialogRef.componentInstance.hintI18N = 'EMAIL';
    return dialogRef.afterClosed();
  }

  deleteOrder(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: new ConfirmDialogModel('CONFIRM_DELETE_SALE', '', 'DELETE', 'delete', 'warn')
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async (confirmDialog: boolean) => {
        if (confirmDialog) {
          // raise exception on ng on init if we ever try to build a deleted component
          this.posOrdersService.delete(this.parentId).subscribe( () => {
            this.router.navigateByUrl('/pos/orders', {replaceUrl: true});
          }, (err) => {
            // tslint:disable-next-line:no-string-literal
            this.notificationService.errorI18N(err.error['detail']);
          });
        } else {
          // cancelled
        }
      });
  }

  printTicket(): void {
    // tslint:disable-next-line:max-line-length
    window.open(`${environment.apiUrl}/pos/order/reports/${this.parentId}/ticket?tk=${this.usersService.userTokenValue.token}&print=1`, '_blank');
  }

  async assignTicketToClient(): Promise<void> {
    await this.checkClientAssigned();
  }

  async printInvoice(): Promise<void> {
    const client = await this.checkClientAssigned();

    if (client == null) {
      this.notificationService.errorI18N('SALE_MUST_BE_ASSIGNED_TO_CLIENT_BEFORE_SENDING_IT');
      return;
    }

    const r = await this.usersService.confirmInvoiceInformation(client, ClientsDetailComponent).toPromise();
    if (r == null) {
      await this.notificationService.infoI18N('INVOICE_PRINTING_CANCELLED_BY_USER');
      return;
    }

    // tslint:disable-next-line:max-line-length
    window.open(`${environment.apiUrl}/pos/order/reports/${this.parentId}/invoice?tk=${this.usersService.userTokenValue.token}&print=1`, '_blank');
  }

  changeInvoiceSeries(selectedSeries: number): void {
    // TODO: check server response, if we have a new invoice number or if we could not change because we are not the last on the series
    if (selectedSeries === this.parentItem.invoiceSeries) {
      return;
    }
    this.posOrdersService.modify(this.parentId, {invoiceSeries: selectedSeries}).subscribe((r) => {
      this.parentItem.invoiceNumber = r.invoiceNumber;
      this.parentItem.invoiceSeries = r.invoiceSeries;
      this.parentItem.invoiceDate = r.invoiceDate;
    }, (err) => {
      this.notificationService.warningI18N(err.error[0]);

      // this is just to trigger the change detection and make select go back to the previous value:
      const oldSeries = this.parentItem.invoiceSeries;
      this.parentItem.invoiceSeries = 99999;
      setTimeout(() => {
        this.parentItem.invoiceSeries = oldSeries;
      });

    });
  }

  generateInvoice(): void {
    this.posOrdersService.generateInvoice(this.parentId).subscribe((r) => {
      this.parentItem.invoiceNumber = r.invoiceNumber;
      this.parentItem.invoiceSeries = r.invoiceSeries;
      this.parentItem.invoiceDate = r.invoiceDate;
    });
  }

  deleteItem(): void {
    this.deleteOrder();
  }
}
